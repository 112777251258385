import * as React from "react"
import moment from "moment"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const EventSection = ({ dates, name, desc, location, image, pdf, link, tel }) => (
  <section>
    <div className="container mx-auto px-4 py-20">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 pb-8 md:p-0">
          <h2 className="text-3xl text-center py-2">{ name }</h2>
          <h3 className="text-xl text-center font-light pb-4">
            {
              dates.length > 1 ?
              <span>{ dates[0].format("dddd, MMMM Do, YYYY") } through { dates[1].format("dddd, MMMM Do, YYYY") }</span>
              : <span>{ dates[0].format("dddd, MMMM Do, YYYY") }</span>
            }
          </h3>
          <h3 className="text-xl text-center font-light pb-4">
            <span className="font-normal">{ location.place }</span><br />
            <a className="underline text-blue-600" href={ location.maps_url } target="_blank" rel="noreferrer">{ location.address }</a><br />
            <a className="underline text-blue-600" href={ `tel:${tel}` }>{ tel }</a>
          </h3>
          <p className="py-2">
            { desc }
          </p>
          {
            pdf ?
            <p className="py-2">
              <a className="underline text-blue-600" href={ pdf } target="_blank" rel="noreferrer">
                Download Registration Form/Flyer (PDF)
              </a>
            </p>
            : null
          }
        </div>
        <div className="w-full md:w-1/2">
          { image ? image : null}
        </div>
      </div>
    </div>
  </section>
)

const EventsPage = ({ data }) => {
  const events = [
    {
      dates: [moment(new Date("2021-10-01T11:00:00")), moment(new Date("2021-10-03T11:00:00"))],
      name: "68th Michigan State AA Convention",
      desc: "Hosted by Area 34, Western Lower Michigan & The Eastern U.P. Please call for more information about reservations and download the PDF flyer for this event below.",
      location: {
        maps_url: "https://goo.gl/maps/c5ZMEEoquuPKnKzh9",
        place: "Shanty Creek Resort",
        address: "5780 Shanty Creek Rd, Bellaire, MI 49615"
      },
      image: <StaticImage width={400} objectFit="contain" src="../images/68thconvention.png" alt="68th AA Convention Logo" />,
      pdf: "http://www.dist26aa.org/Michigan_State_Convention_Flyer.pdf",
      link: "https://www.shantycreek.com/reservations/2021-mi-state-convention/",
      tel: "866-695-5010"
    }
  ]

  const event = events.length ? events[0] : null
  const e_date = events.length ? event.dates[0].format("MMM Do") : null
  const title = e_date ? e_date + " " + event.name : "Events"

  return (
    <Layout>
      <Seo title={title} image="68thconvention.png" slug="events" />
      <header className="bg-azure">
        <div className="container mx-auto px-4 py-16">
          <h1 className="text-4xl">Events</h1>
        </div>
      </header>
      {
        events.length ?
        events.map((event, key) => <EventSection key={key} {...event} />)
        : null
      }
    </Layout>
  )
}

export default EventsPage
